/// <reference path="./theme.d.ts" />

import { ChipProps } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { mono } from './myfonts';

export type AvatarSize = 'large' | 'medium' | 'small' | 'xlarge';
export type ProfileScoreCircularSize = 'large' | 'medium' | 'small';

// Create a theme instance.
let theme = createTheme({
  palette: {
    background: {
      default: '#f9fafb',
    },
    chip: {
      main: '#eaf1fb',
    },
    deleted: {
      contrastText: '#ffffff',
      main: '#D32F2F',
    },
    error: {
      main: '#F00622',
    },
    iglu_ice: {
      contrastText: '#ffffff',
      main: '#8EE8FF',
    },
    iglu_jeans: {
      contrastText: '#ffffff',
      main: '#14B5DD',
    },
    iglu_pacific: {
      contrastText: '#ffffff',
      main: '#3A5799',
    },
    iglu_sapphire: {
      contrastText: '#ffffff',
      main: '#4285F4',
    },
    iglu_sky: {
      contrastText: '#ffffff',
      main: '#00C5F6',
    },
    iglu_twilight: {
      contrastText: '#ffffff',
      main: '#021033',
    },
    pending: {
      contrastText: '#ffffff',
      main: '#FFA726',
    },
    primary: {
      dark: '#585858',
      main: '#0A73E0',
    },
    rejected: {
      contrastText: '#ffffff',
      main: '#ED6C02',
    },
    secondary: {
      dark: '#C4C4C4',
      main: '#A9C0D7',
    },
    verified: {
      contrastText: '#ffffff',
      main: '#4FC555',
    },
  },
  topNavigation: {
    active: '#0A73E0',
    main: '#82A3C5',
  },
  typography: {
    allVariants: {
      color: '#585858',
    },
    bodyNumbers: {
      fontFamily: mono.style.fontFamily,
    },
    fontFamily: 'var(--base-font)',
    fontSize: 12,
    fontWeightMedium: '500',
    fontWeightRegular: '400',
  },
});

theme = createTheme(theme, {
  avatarSizes: {
    large: {
      height: theme.spacing(9),
      width: theme.spacing(9),
    },
    medium: {
      height: theme.spacing(5),
      width: theme.spacing(5),
    },
    small: {
      height: theme.spacing(3.5),
      width: theme.spacing(3.5),
    },
    xlarge: {
      height: theme.spacing(19),
      width: theme.spacing(19),
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: theme.palette.common.white,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {},
    },
    MuiButton: {
      styleOverrides: {
        outlinedSecondary: {
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        },
        root: {
          borderRadius: '31px',
          textTransform: 'capitalize',
        },
        textPrimary: {
          textTransform: 'uppercase',
        },
        textSecondary: {
          '&:hover': {
            borderRadius: '4px',
          },
          color: '#585858',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: theme.palette.secondary.dark,
        },
        root: {
          ...theme.typography.bodySmall,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSuccess: {
          backgroundColor: '#15D740',
        },
        colorWarning: {
          backgroundColor: '#FFA31A',
        },
        label: {
          color: '#ffffff',
        },
        root: ({ ownerState }: { ownerState: ChipProps }) => ({
          ...(ownerState.variant === 'skills' && {
            '& .MuiChip-label': { color: '#000000' },
          }),
        }),
      },
      variants: [
        {
          props: { variant: 'skills' },
          style: {
            backgroundColor: 'rgba(25,118,210,0.1)',
            borderRadius: theme.spacing(1),
          },
        },
      ],
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          padding: theme.spacing(0, 2),
        },
        columnHeader: {
          padding: theme.spacing(0, 2),
        },
        columnHeaderRow: {
          '&&&&': { background: 'none' },
        },
        columnSeparator: {
          display: 'none',
        },
        root: {
          border: 'none',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fontFamily,
          fontSize: 14,
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: theme.spacing(3),
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: theme.typography.fontFamily,
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 300,
          lineHeight: theme.spacing(3),
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          mHeading: 'p',
        },
      },
    },
  },
  cssVariables: true,
  profileScoreCircularSizes: {
    large: {
      height: `186px`,
      width: `186px`,
    },
    medium: {
      height: `100px`,
      width: `100px`,
    },
    small: {
      height: `50px`,
      width: `50px`,
    },
  },
  typography: {
    body: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.spacing(3),
    },
    bodyBold: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.spacing(3),
    },
    bodySmall: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 10,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.spacing(3),
    },
    bodySmallBold: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 10,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.spacing(3),
    },
    headingLarge: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 24,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.spacing(6),
    },
    headingMedium: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.spacing(3),
    },
    headingSmall: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.spacing(3),
    },
  },
});

export { theme };
