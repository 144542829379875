import { AppProps } from 'next/app';

import createCache from '@emotion/cache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material/styles';

import { getMyFonts, poppins } from '@itp/component-library';
import { AppSkeleton, MainPageLayout } from '@itp/myaccount';

import '../styles/global.css';

type AppPropsWithLayout = AppProps & {
  emotionCache?: EmotionCache;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  getMyFonts(poppins);

  const cache = createCache({
    key: 'css',
    prepend: true,
  });

  const getLayout =
    (
      Component as {
        getLayout?: (page: React.ReactElement<unknown>) => React.ReactElement<unknown>;
      }
    ).getLayout || ((page: React.ReactElement<unknown>) => page);

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cache}>
        <AppSkeleton>
          <MainPageLayout>{getLayout(<Component {...pageProps} />)}</MainPageLayout>
        </AppSkeleton>
      </CacheProvider>
    </StyledEngineProvider>
  );
}
